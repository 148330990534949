import { Controller } from "@hotwired/stimulus";
import "./form_controller.scss";

type ContentType =
  | "web"
  | "text"
  | "animation"
  | "audio"
  | "video"
  | "image"
  | "interactive"
  | "printed_text_read_out_loud"
  | "song_music"
  | "kampus"
  | "immersive";
type IconType = "audio" | "animation" | "web" | "video";

export default class extends Controller {
  // static outlets = ["editor-links-page-map"];
  // editorLinksPageMapOutlet!: PageMapController;

  static targets = [
    "linkContentRemoveButton",
    "linkContentUploadInput",
    "linkContentUrlInput",
    "androidContentUploadInput",
    "iosContentUploadInput",
    "iconTypeSelect",
    "contentTypeSelect",
    "iconTypeHidden",
  ];
  //linkContentRemoveButtonTarget!: HTMLButtonElement;
  linkContentUploadInputTarget!: HTMLInputElement;
  linkContentUrlInputTarget!: HTMLInputElement;
  androidContentUploadInputTarget!: HTMLInputElement;
  iosContentUploadInputTarget!: HTMLInputElement;
  iconTypeSelectTarget!: HTMLSelectElement;
  contentTypeSelectTarget!: HTMLSelectElement;
  iconTypeHiddenTarget!: HTMLInputElement;

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  iconFilters: any = {
    audio: [
      { value: "audio", text: "Audio" },
      { value: "song_music", text: "Song or music" },
      {
        value: "printed_text_read_out_loud",
        text: "Printed text read out loud",
      },
    ],
    animation: [{ value: "animation", text: "3D" }],
    image: [{ value: "image", text: "Image" }],
    web: [
      { value: "audio", text: "Audio" },
      { value: "text", text: "Text" },
      { value: "interactive", text: "Interactive" },
      { value: "image", text: "Image" },
      { value: "video", text: "Video" },
      { value: "song_music", text: "Song or music" },
      { value: "web", text: "Web" },
      { value: "kampus", text: "Kampus" },
    ],
    video: [{ value: "video", text: "Video" }],
    immersive: [{ value: "immersive_reader", text: "Immersive Reader" }],
  };

  connect() {
    this.updateIconOptions(
      this.contentTypeSelectTarget.value as ContentType,
      this.iconTypeHiddenTarget.value as IconType,
    );
    this.updateContentTypeDisplays(
      this.contentTypeSelectTarget.value as ContentType,
    );
    this.updateCursorIcon(
      this.contentTypeSelectTarget.value as ContentType,
      this.iconTypeSelectTarget.value as IconType,
    );
  }

  getImageClass(contentTypeValue: ContentType) {
    switch (contentTypeValue) {
      case "web":
        return "icon_web_content";
      case "text":
        return "icon-text_content";
      case "animation":
        return "icon-scene_content";
      case "audio":
        return "icon-audio_content";
      case "video":
        return "icon-video_content";
      case "immersive":
        return "icon-immersive_reader_content";
      case "song_music":
        return "icon-icon_song_music";
      case "image":
        return "icon-image_content";
      case "interactive":
        return "icon-interactive_content";
      case "printed_text_read_out_loud":
        return "icon-printed_text_read_out_loud";
      case "kampus":
        return "icon-kampus_content";
      default:
        return "icon-unknown_content";
    }
  }

  updateCursorIcon(contentTypeValue?: ContentType, iconTypeValue?: IconType) {
    const cursor = document.getElementById("page-map-cursor") as HTMLDivElement;
    if (!cursor) return;

    while (cursor.firstChild) {
      cursor.removeChild(cursor.firstChild);
    }

    const typeValue = iconTypeValue || contentTypeValue;
    const imgClass = !typeValue
      ? "icon-unknown_content"
      : iconTypeValue === "web"
      ? "icon-web_content"
      : this.getImageClass(typeValue);

    const img = document.createElement("div");
    img.classList.add("page-map-icon-new");
    img.classList.add("page-map-icon-new-icon-container");
    img.classList.add(imgClass);

    cursor.appendChild(img);
  }

  updateIconOptions(filter: ContentType, selectedIconType: IconType) {
    const filterOptions = this.iconFilters[filter];
    if (!filterOptions) return;

    const element = this.iconTypeSelectTarget;
    while (element.firstChild) {
      element.removeChild(element.firstChild);
    }

    filterOptions.forEach((icon: { value: string; text: string }) => {
      const option = document.createElement("option");
      option.value = icon.value;
      option.text = icon.text;
      option.selected = icon.value === selectedIconType;
      element.appendChild(option);
    });
  }

  onLinkContentUrlInput(e: Event) {
    const weblinkUrl = (e.target as HTMLInputElement).value;
    this.linkContentUploadInputTarget.disabled = !!weblinkUrl;
  }

  onLinkContentUploadInputChange() {
    //this.linkContentRemoveButtonTarget.classList.remove("display-none");
    this.linkContentUrlInputTarget.disabled = true;
  }

  //onLinkContentRemoveButtonClick() {
  //  this.linkContentUploadInputTarget.value = "";
  //  this.linkContentRemoveButtonTarget.classList.add("display-none");
  //  this.linkContentUrlInputTarget.disabled = false;
  //}

  onIconTypeSelectChange() {
    this.updateCursorIcon(
      this.contentTypeSelectTarget.value as ContentType,
      this.iconTypeSelectTarget.value as IconType,
    );
  }

  onContentTypeSelectChange() {
    const contentTypeValue = this.contentTypeSelectTarget.value as ContentType;
    const iconTypeValue = this.iconTypeHiddenTarget.value as IconType;
    this.updateCursorIcon(contentTypeValue);
    this.updateContentTypeDisplays(contentTypeValue);
    this.updateIconOptions(contentTypeValue, iconTypeValue);
  }

  updateContentTypeDisplays(contentType: ContentType) {
    switch (contentType) {
      case "web":
        this.linkContentUrlInputTarget.disabled = false;
        this.linkContentUploadInputTarget.disabled = true;
        this.androidContentUploadInputTarget.disabled = true;
        this.iosContentUploadInputTarget.disabled = true;
        break;
      case "animation":
        this.androidContentUploadInputTarget.disabled = false;
        this.iosContentUploadInputTarget.disabled = false;
        this.linkContentUploadInputTarget.disabled = true;
        this.linkContentUrlInputTarget.disabled = true;
        break;
      case "immersive":
        this.linkContentUrlInputTarget.disabled = true;
        this.linkContentUploadInputTarget.disabled = true;
        this.androidContentUploadInputTarget.disabled = true;
        this.iosContentUploadInputTarget.disabled = true;
        break;
      default:
        this.androidContentUploadInputTarget.disabled = true;
        this.iosContentUploadInputTarget.disabled = true;
        this.linkContentUploadInputTarget.disabled = false;
        this.linkContentUrlInputTarget.disabled = true;
        return;
    }
  }
}
